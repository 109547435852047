
































































import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  name: "Home",
  data() {
    return {
      show: false,
      imgs: [],
      search: "",
    };
  },
  mounted() {
    this.show = true;
    axios
      .get(`https://api-site.roit.ai/api-site/v1/icons`)
      .then((response) => {
        this.imgs = response.data.data.data;
        this.show = false;
      })
      .catch((e) => {
        console.log(e);
        this.show = false;
      });
  },
  computed: {
    arrayIcons(): any[] {
      if (this.search === "" || this.search === null) {
        return this.imgs;
      }
      return this.imgs.filter((item: any): boolean => {
        return item.name.includes(this.search);
      });
    },
  },
  methods: {
    toast(tilte: string, content: string) {
      this.$bvToast.toast(content, {
        title: `icon-r-${tilte}`,
        toaster: "b-toaster-bottom-full",
        solid: true,
      });
    },
    copiar(e: Event) {
      (e.target as HTMLInputElement).select();
      document.execCommand("copy");
    },
  },
});
